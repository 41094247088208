import axios from "@/axios";
import {DateTime} from "luxon";
/**
 * If tool is anything other than adverse/pep or corporate records or news tools,
 * sources values is name of tool itself.
 * @param {string} tool : tool name
 * @returns {array} sources
 */
function getSources(tool, sources) {
    if (tool === "adverse-media-tool" || tool === "company-name" || tool === "news-tool" || tool === "offshoreleaks-tool" || tool === "lexis-bridger-tool") {
        return sources.map((src) => src.toLowerCase());
    } else {
        return [tool];
    }
}

/**
 * Fetch monitoring data from monitoring changes API
 * @param {string} case_id
 * @param {array} sources
 * @param {string} toolName
 * @param {object} getMonitoringDateFilter
 * @param {string} getSelectedToolQuery
 */
export async function monitoringSearchData(case_id, sources, toolName, getMonitoringDateFilter, getSelectedToolQuery, offset, limit=100) {
    const days = 30;
    let acknowledged = "All";

    let endDt = new Date();
    let startDt = new Date(endDt.getTime() - days * 24 * 60 * 60 * 1000);

    const {startDate, endDate, dataType} = getMonitoringDateFilter;
    /**
     * If start date and end date are not set then
     * start date should be before 30 days from current date
     */
    if (startDate && endDate) {
        startDt = new Date(startDate);
        startDt.setHours(0);
        startDt.setMinutes(0);
        startDt.setSeconds(0);
        startDt.setMilliseconds(0);
        let today = new Date();
        endDt = new Date(endDate);
        endDt.setHours(today.getHours());
        endDt.setMinutes(today.getMinutes());
        endDt.setSeconds(today.getSeconds());
        startDt = DateTime.fromMillis(Date.parse(startDt));
        startDt = startDt.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
        endDt = DateTime.fromMillis(Date.parse(endDt));
        endDt = endDt.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
        // startDt = startDate;
        // endDt = endDate;
    }
    /**
     * If acknowledged is not set then
     * default acknowledged should be 'New'
     */
    if (dataType) {
        acknowledged = dataType;
    }
    const body = {
        case_id,
        query_id: getSelectedToolQuery.query_id,
        tool: toolName,
        sources: getSources(toolName, sources), // getSources()
        start_date: startDt,
        end_date: endDt,
        acknowledged: acknowledged,
        offset: offset,
        limit: limit,
    };

    const {data: monitoring_data} = await axios.post(`/service/get-latest-version-cards`, body);
    console.log(monitoring_data)
    let values = [];
    let totalResults = 0;
    if (monitoring_data?.data?.length > 0) {
        monitoring_data?.data.forEach((mon_src, index) => {
            console.log(index);
            mon_src.data.monitoring_id = mon_src.doc_id;
            mon_src.data.doc_id = mon_src.doc_id;
            mon_src.data.changes_viewed = mon_src?.changes_viewed ?? false;
            mon_src.data.monitoring_result_status = mon_src.status;
            mon_src.data.monitoring_status = mon_src?.monitoring_status ?? false;
            mon_src.data.sources = mon_src.data?.sources?.filter((el) => sources?.includes(el));
            mon_src.data.saved = mon_src?.saved;
            mon_src.data.doc_source = mon_src?.source_name;
            mon_src.data.query_id = mon_src?.query_id;
            mon_src.data.history_count = mon_src?.history_count;
            mon_src.data["monitoringDate"] = mon_src?.timestamp;
            mon_src.data["parentMonitoringDate"] = mon_src?.timestamp;
            // mon_src.data._id = mon_src.data._id;
            mon_src.data.mongo_doc_id = mon_src.data?.mongo_doc_id;
            if (toolName!='lexis-bridger-tool')
            { values.push(mon_src?.data);}
            else {
                values.push(mon_src)
            }
            
        });
        totalResults = monitoring_data.total_results;
    }
    const total_results = monitoring_data?.total_results;
    return {values, totalResults, total_results};
    // }
}

/**
 * Fetch monitoring card history older versions
 * @param {object} payload
 */
export async function monitoringHistoryData(payload, source, toolName) {
    const {data: monitoring_data} = await axios.post(`/service/get-old-version-cards`, payload);
    let values = [];
    if (monitoring_data?.data?.length > 0) {
        monitoring_data?.data?.forEach((mon_src, index) => {
            console.log(index);
            mon_src.data.monitoring_id = mon_src.doc_id;
            mon_src.data.doc_id = mon_src.doc_id;
            mon_src.data.changes_viewed = mon_src.changes_viewed;
            mon_src.data.monitoring_result_status = mon_src.status;
            mon_src.data.monitoring_status = mon_src.monitoring_status;
            mon_src.data.sources = mon_src.data?.sources?.filter((el) => source?.includes(el));
            mon_src.data.saved = mon_src.saved;
            mon_src.data.doc_source = payload.source;
            mon_src.data.query_id = mon_src.query_id;
            mon_src.data["monitoringDate"] = mon_src.timestamp;
            mon_src.data["parentMonitoringDate"] = payload.parentMonitoringDate;
            if (toolName!='lexis-bridger-tool')
            { values.push(mon_src?.data);}
            else {
                values.push(mon_src)
            }
        });
    }
    return values;
}

/**
 * Return classes for background color and text
 * @param {string} status of the monitoring result card
 * @returns
 */
export function getBackgroundStatus(status) {
    let classes = "";
    if (status === "added") {
        classes = " bg-green-200  text-green-800 ";
    }
    if (status === "deleted") {
        classes = " bg-red-200  text-red-800 ";
    }
    if (status === "changed" || status === "updated") {
        classes = " bg-yellow-200  text-yellow-800 ";
    } else {
        classes = "  bg-blue-200 text-blue-800 ";
    }

    if (this.isOldVersion) classes += "left-4";
    return classes;
}

/**
 * Get an ID for result card
 * @param {number} index Index number of result card
 */
export function getResultsID(index) {
    return `result-${index}`;
}

/*
 *Get mark as visted cards
 * @param {object} payload
 */
export async function getMarkAsDone(payload, case_id, visited) {
    const {data: markAsDone} = await axios.post("/data-monitoring/mark-monitered-data-visited", {
        card_info: payload,
        case_id: case_id,
        visited,
    });
    return markAsDone;
}

export default {monitoringSearchData, monitoringHistoryData, getBackgroundStatus, getResultsID, getMarkAsDone};
